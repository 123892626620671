/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.3 ./src/Voe.glb
*/

import React, { useRef } from 'react'
import { useGLTF, Sparkles } from '@react-three/drei'

export function Voe(props) {
  const { nodes, materials } = useGLTF('/Voe.glb')
  return (
    <group {...props} dispose={null}>
      <Sparkles count={100} scale={1} size={20} speed={1}  />
      <mesh geometry={nodes.HoodieSub3V2Mesh.geometry} material={materials['HoodieMtl.004']}  position={[3, -14, -9]} scale={0.1} />
    </group>
  )
}

useGLTF.preload('/Voe.glb')
